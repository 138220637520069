import {mapState} from 'vuex'
import ROUTE_NAME from '@/router/routeName'

import pages from '../../mixins/pages.js'
import chartDynamics from '../../components/dynamicsSalesChart/'
import tableDynamicsSales from '../../components/dynamicsSalesTable/'

export default {
  mixins: [
    pages
  ],

  components: {
    'v-chart-dynamics': chartDynamics,
    'v-table-dynamics-sales': tableDynamicsSales
  },

  metaInfo () {
    return {
      title: `${this.title.primary} / ${this.title.secondary} / ${this.projectData.label} (${this.projectData.id})`
    }
  },

  computed: {
    ...mapState({
      frequency: state => state.bi.comparison.frequency,
      traffic: state => state.bi.comparison.traffic
    }),

    /* Заголовки страницы */
    title () {
      return {
        primary: this.$t('routes.bi')[ROUTE_NAME.BI],
        secondary: `${this.$t('routes.bi')[ROUTE_NAME.BI_COMPARISON]} / ${this.$t('services.bi.comparison')['Sales dynamics']}`
      }
    }
  },

  methods: {
    /* Загрузка данных */
    async initData () {
      if (this.hasSegments) {
        this.preloader = true

        let {
          search_system,
          visibility,
          category_id,
          group_id,
          query_group_id,
          kg_category_id,
          kg_group_id,
          kg_query_group_id,
          date
        } = this.urlFilters

        try {
          await this.getFrequency({
            query: {
              ...this.extendedDates,
              project_id: this.activeProjectId,
              search_engine: search_system,
              visibility,
              category_id,
              group_id,
              query_group_id
            }
          })
        } catch (error) {
          this.$notify({type: 'error', title: error.message})
        }

        try {
          await this.getTraffic({
            query: {
              project_id: this.activeProjectId,
              visibility,
              date_from: date.from,
              date_to: date.to,
              category_id,
              group_id,
              query_group_id,
              /* Флаг для отключения ограничения */
              all: 1,
              skip_regionality: 1,
              search_engine: search_system
            }
          })
        } catch (error) {
          this.$notify({type: 'error', title: error.message})
        }

        this.preloader = false
      }
    }
  },

  async created () {
    this.preloader = true

    if (!Object.keys(this.projectData).length) {
      try {
        await this.getProject({
          query: {
            project_id: this.activeProjectId,
            skip_regionality: 1
          }
        })
      } catch ({message}) {
        this.$notify({type: 'error', title: message})
      }
    }

    await this.initUpdates()
    await this.initMetrics()

    this.checkFiltersSegments(this.initData)

    this.pageLoader = false
  },

  watch: {
    async $route (from, to) {
      if (this.viewPage) {
        let qt = to.query
        let qf = from.query

        let comStates = function (filters) {
          return filters.some(item => {
            return (qt[item] || qf[item]) && qt[item] !== qf[item]
          })
        }

        switch (true) {
          case to.params.activeProject !== from.params.activeProject:
            this.preloader = true
            await this.initMetrics()
            await this.initUpdates()
            await this.initData()
            break

          case comStates(['search_system']):
            this.preloader = true
            await this.initUpdates()
            await this.initData()
            break
        }
      }
    }
  }
}
