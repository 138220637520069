var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-line',{attrs:{"mouseEvents":['mouseover']},on:{"mouseover":function($event){return _vm.$emit('mouseover')}}},[_c('vue-item',[_vm._v(" "+_vm._s(_vm.segment.name)+" ")]),_c('vue-item',[(_vm.segment.date_raw)?[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.segment.date_raw,'LL'))+" ")]:_vm._e()],2),_c('vue-item',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.segmentWs)+" ")]),_c('vue-item',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.segmentTraffic)+" ")]),_c('vue-item',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.segmentCount)+" ")]),_c('vue-item',{attrs:{"align":"right"}},[(_vm.visibleCompare)?_c('vue-element',{attrs:{"type":"label","data":_vm.comparisonData.transactions,"labelNote":"X"}}):[_vm._v(" "+_vm._s(_vm.transactions)+" ")]],2),_c('vue-item',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.conversion)+" ")]),_c('vue-item',{attrs:{"align":"right"}},[(_vm.visibleCompare)?_c('vue-element',{attrs:{"type":"label","data":_vm.comparisonData.transaction_revenue,"labelNote":"X"}}):[_vm._v(" "+_vm._s(_vm.transaction_revenue)+" ")]],2),_c('vue-item',{staticClass:"segment_action",attrs:{"align":"center"}},[_c('div',{staticClass:"table-line__actions"},[_c('v-button',_vm._b({directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"content":_vm.$t(_vm.contentPath)['Remove this segment from the comparison']}},'v-button',{
            height: '32px',
            type: 'text-base',
            paddingLeft: '6px',
            paddingRight: '6px',
            to: { query: _vm.routeDelete.query }
          },false),[_c('icon',_vm._b({},'icon',{
              name: 'delete',
              fill: '#9D99AA'
            },false))],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }