<template>
	<v-chart
      v-model="salesLinearCharts"
      lib="highcharts"
      dataforce
  />
</template>

<script>
import {roundNumber} from '@/function'

export default {
  props: {
    traffic: {
      type: Object,
      default: function () {
        return {}
      }
    },

    urlFilters: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  data: () => {
    const contentPath = 'services.bi.comparison'

    return {
      contentPath,

      lineOptions: {
        chart: {
          type: 'line'
        },
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value: %b %Y}'
          }
        }
      }
    }
  },

  computed: {
    salesLinearCharts () {
      const series = []

      if (this.traffic && this.traffic.items && this.traffic.items.length) {
        this.traffic.items.forEach(el => {
          const entity = {
            id: el.entity.name,
            name: el.entity.name,
            data: el.data.sort((a, b) => a.date - b.date).map(item => ({
              'x': item.date * 1000,
              'y': this.urlFilters.metric === 'conversion' ? roundNumber((item.transactions / item.traffic) * 100, 1) || 0 : item[this.urlFilters.metric]
            })),
            marker: {
              enabled: false
            }
          }

          series.push(entity)
        })
      }

      return {
        ...this.lineOptions,
        yAxis: {
          showLastLabel: true
        },
        chart: {
          'type': 'line'
        },
        tooltip: {
          pointFormat: `
	          <div style="line-height: 18px;">
	              <span style="font-size: 16px; color:{point.color};">\u25CF</span> {series.name}: <b>{point.y}</b>
	          </div>
	        `
        },

        xAxis: {
          type: 'datetime',
          plotBands: [
            {
              color: 'rgba(41,121,255,0.05)',
              from: 0,
              to: this.formatterDate(this.urlFilters.date.from)
            },
            {
              color: 'rgba(41,121,255,0.05)',
              from: this.formatterDate(this.urlFilters.date.to),
              to: 9999999999999
            }
          ],
          plotLines: [
            {
              value: this.formatterDate(this.urlFilters.date.from),
              width: 1,
              color: 'rgba(41,121,255,0.2)',
              label: {
                text: this.$t(this.contentPath)['Analysis start date']
              }
            },
            {
              value: this.formatterDate(this.urlFilters.date.to),
              width: 1,
              color: 'rgba(41,121,255,0.2)',
              label: {
                text: this.$t(this.contentPath)['Analysis end date']
              }
            }
          ]
        },

        series
      }
    }
  },

  methods: {
  	formatterDate (date) {
      try {
      	let newDate = +this.$moment.utc(date).format('x')
      	return date && !isNaN(newDate) ? newDate : undefined
      } catch (_) {}
    }
  }
}
</script>

<style lang="scss" scoped></style>
