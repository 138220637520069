<template>
  <div @mouseleave="clearActiveSegment">
  	<vue-table
      small-padding
      head-background
      fixed-bar
      fixed-head
      :hoverable-rows="false"
    >
      <template slot="colgroup">
        <col width="auto">
        <col width="auto">
        <col width="auto">
        <col width="auto">
        <col width="auto">
        <col width="12.5%">
        <col width="12.5%">
        <col width="auto">
      </template>

      <template slot="head">
        <vue-line type="head">
          <vue-item valign="bottom" class="segment_name">
          	{{ $t(contentPath)['Segment name'] }}
          </vue-item>
          <vue-item valign="bottom" class="segment_create_date">
          	{{ $t(contentPath)['Creation date'] }}
          </vue-item>
          <vue-item valign="bottom" class="segment_ws">
          	{{ isWestProject ? 'Search Volume' : '“WS”' }}
          </vue-item>
          <vue-item valign="bottom" class="segment_traffic">
          	{{ $t(contentPath)['Traffic'] }}
          </vue-item>
          <vue-item valign="bottom" class="segment_counts">
          	{{ $t(contentPath)['Number of elements'] }}
          </vue-item>
          <vue-item class="segment_transactions">
            {{ $t(contentPath)['Transactions'] }}
          </vue-item>
          <vue-item class="segment_transaction_revenue">
            {{ $t(contentPath)['Conversion'] }}
          </vue-item>
          <vue-item class="segment_transaction_revenue">Выручка</vue-item>
          <vue-item valign="bottom" align="center" class="segment_delete">
          	{{ $t(contentPath)['Delete'] }}
          </vue-item>
        </vue-line>
      </template>

      <template slot="body">
  			<v-line
          class="table-line"
  				v-for="(segment, i) in segmentsListFrequency.list"
  				:key="i"
  				v-bind="{
  					segment,
            activeSegment,
            searchSystem,
            activeSalesMetric
  				}"
          @mouseover="activateSegment(segment)"
          ref="line"
  			/>
      </template>
    </vue-table>
  </div>
</template>

<script>
import line from './dynamicsSalesTableLine.vue'

export default {
  components: {
    'v-line': line
  },

  props: {
    segmentsListFrequency: {
      type: Object,
      default: function () {
        return {}
      }
    },

    urlFilters: {
      type: Object,
      default: function () {
        return {}
      }
    },

    isWestProject: {
      type: Boolean,
      default: false
    },

    activeSalesMetric: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },

  data () {
    const contentPath = 'services.bi.comparison'
    const contentPathFilters = 'services.bi.comparison.filters'

    return {
      contentPath,
      contentPathFilters,

      activeSegment: undefined
    }
  },

  computed: {
    searchSystem () {
      return this.urlFilters.search_system
    }
  },

  methods: {
    activateSegment (segment) {
      this.$set(this, 'activeSegment', segment)
    },

    clearActiveSegment () {
      this.$set(this, 'activeSegment', undefined)
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-line {
    &:hover /deep/ .table-item {
      background: #FAFAFA;
    }
  }
</style>
